import React from "react";
import "./user.css";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/Info";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { fetchAddresses } from "../clients/multiAddressInput";

export const userTypes = [
  {
    id: 1,
    label: "Admin",
  },

  {
    id: 3,
    label: "Client",
  },
];
const moduleList = [
  {
    id: 1,
    name: "Dashboard",
  },
  {
    id: 2,
    name: "Reports List",
  },
  {
    id: 3,
    name: "Clients",
  },
  {
    id: 4,
    name: "Users",
  }
];



export default function EditUser() {
  const { id } = useParams();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);
  const [roles, setRoles] = React.useState("");
  const [executing, setExecuting] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [modules, setModules] = React.useState(moduleList);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [clientID, setclientID] = React.useState(null);
  const [type, setType] = React.useState({});
  const [allAddress, setAllAddress] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [SelectedAddress, setSelectedAddress] = React.useState("");


  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = async () => {
    setExecuting(true);
    var jsonData = {
      userName: userName,
      password: password,
      clientId:clientID,
      address:
      SelectedAddress ||
        `${allAddress[0]?.id},${allAddress[0]?.shipping?.id}`,
      modules:
        roles?.id !== 1
          ? (selectedModules || [])?.map((m) => m.id).join(",")
          : null,
      type: type?.id,
    };
 

    let ret = id
      ? axiosWithToken.patch(BASE_URL + `users/${id}`, jsonData)
      : axiosWithToken.post(BASE_URL + `users`, jsonData);
    ret
      .then((res) => {
        id
          ? toast.success("Updated Sucessfully!")
          : toast.success("User Create Sucessfully!");
        refresh();
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

   const fetchAddresses = async (addressIds, shippingAddressIds) => {
    // Check for invalid inputs
    let allIDS = `${addressIds},${shippingAddressIds}`;
    const containsNonText = /[a-zA-Z]/.test(allIDS);
  
    if (containsNonText) {
      // If invalid IDs are found, return an empty array or handle the case accordingly
      return [];
    }
  
    // Convert input strings to arrays of numbers
    const parseIds = (ids) => ids.split(",").map(Number);
  
    const sqlQuery = {
      query: `SELECT * FROM addresses WHERE id IN (${
        parseIds(addressIds).join(",") || 0
      }, ${parseIds(shippingAddressIds).join(",") || 0})`,
    };
  
    try {
      const addressesResponse = await axiosWithToken.post(
        BASE_URL + `dynamic`,
        sqlQuery
      );
      const addresses = addressesResponse.data; // Assuming response data contains addresses
  
      const billingAddressArray = parseIds(addressIds);
      const shippingAddressArray = parseIds(shippingAddressIds);
  
      const result = billingAddressArray.map((id, index) => {
        const address = addresses.find((a) => a.id === id);
        const shippingId = shippingAddressArray[index];
        const shippingInfo = addresses.find((a) => a.id === shippingId);
  
        const addressId = address ? address.id : null;
        const addressAddress = address ? address.address : "";
  
        return {
          id: addressId,
          address: addressAddress,
          shipping: shippingInfo
            ? { id: shippingInfo.id, address: shippingInfo.address }
            : { id: null, address: "" },
        };
      });
  
      return result;
    } catch (error) {
      // Handle any errors that occur during the process
      console.error("Error fetching addresses:", error);
      return []; // Return an empty array or handle the error accordingly
    }
  };


  async function fetchUserDetails() {
    await axiosWithToken.get(BASE_URL + `users/${id}`).then(async(res) => {


setSelectedAddress(res.data[0].address)
      setUserName(res.data[0].userName);
      setPassword(res.data[0].password);
      setType(userTypes?.find((ele)=>ele.id == res.data[0].type));
      setclientID(res.data[0].clientId)

      let Client = clients?.filter((e) => e.id == res.data[0].clientId)[0];

      const addressIds = Client?.address;
      const shippingAddressIds = Client?.shippingAddress;

      let newAddressArray = await fetchAddresses(
        addressIds,
        shippingAddressIds
      );
      setAllAddress(newAddressArray);

      if (res.data[0].password) {
        let vals = passVal;

        let lowerCaseLetters = /[a-z]/g;
        res.data[0].password.match(lowerCaseLetters)
          ? (vals[0] = 1)
          : (vals[0] = 0);

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        res.data[0].password.match(upperCaseLetters)
          ? (vals[1] = 1)
          : (vals[1] = 0);

        // Validate numbers
        let numbers = /[0-9]/g;
        res.data[0].password.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

        // Validate length
        res.data[0].password.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
        res.data[0].password.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

        setPasswordVal(vals);
      }
      res.data[0].modules
        ? setSelectedModules(
            res.data[0].modules?.split(",").map((m) => moduleList[m - 1] || [])
          )
        : setSelectedModules([]);
    });
  }

  const fetchClients = () => {
    try {
      axiosWithToken
        .get(BASE_URL + `clients`)
        .then((res) => {
          let data = res.data;
          setClients(data);
        })

    } catch (e) {
      console.log("Failed to fetch  Clients : ", e);
    }
  };

  const debouncedFetchAddresses = async (addressIds, shippingAddressIds) => {
    setLoading(true);
    const newAddressArray = await fetchAddresses(
      addressIds,
      shippingAddressIds
    );
    setAllAddress(newAddressArray);
    setLoading(false);
  };

  React.useEffect(() => {
    if (id) {
      fetchUserDetails();
    }
  }, [id,clients]);


  React.useEffect(()=>{
    fetchClients()
  },[])

 
  return (
    <Paper sx={{ px: 2 }}>
   
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
            style={{ position: "relative" }}
          />
          <InfoIcon
            style={{ color: "gray", position: "absolute", height: "20px" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {isHovered && (
            <div>
              <p id="letter" className={passVal[0] ? "valid" : "invalid"}>
                A <b>lowercase</b> letter
              </p>
              <p id="capital" className={passVal[1] ? "valid" : "invalid"}>
                A <b>capital (uppercase)</b> letter
              </p>
              <p id="number" className={passVal[2] ? "valid" : "invalid"}>
                A <b>number</b>
              </p>
              <p id="lengthMin" className={passVal[3] ? "valid" : "invalid"}>
                Minimum <b>8 characters</b>
              </p>
              <p id="lengthMax" className={passVal[4] ? "valid" : "invalid"}>
                Maximum <b>32 characters</b>
              </p>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={3}>
          <Autocomplete
            key={type}
            size="small"
            id="combo-box-demo"
            options={userTypes}
            getOptionLabel={(option) => option.label || ""}
            value={type}
            renderInput={(params) => (
              <TextField {...params} label="User Types *" />
            )}
            onChange={(e, val) => {
              setType(val);
            }}
          />
        </Grid>


        {type?.id == 3 && (
          <Grid item xs={3} key={type?.id}>
            <Autocomplete
              size="small"
              options={clients}
              value={
                clients?.filter((ele) => ele?.id == clientID)?.[0] || null
              }
              getOptionLabel={(option) => `${option.id}, ${option.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a client"
                  variant="outlined"
                />
              )}
              onChange={(event, value) => {
                const addressIds = value?.address;
                const shippingAddressIds = value?.shippingAddress;
                // Debounce the fetchAddresses function here
              debouncedFetchAddresses(addressIds, shippingAddressIds);
                setclientID(value?.id);
              }}
            />
             {loading && <p>Loading...</p>} 
          </Grid>
        )}


<Grid item xs={12} sm={6} md={3} lg={3} id="quotation_create_address">
          {allAddress?.length === 1 || id ? (
            <TextField
            
              value={
                allAddress?.length === 1
                  ? allAddress[0]?.address 
                  : allAddress.find(
                      (e) => e.id == (SelectedAddress).split(",")?.[0]
                    )?.address || ""
              }
              id="outlined-basic"
              label="Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
              options={allAddress}
              size="small"
              getOptionLabel={(option) => option?.address}
              onChange={(event, value) => {
                setSelectedAddress(
                  `${value?.id},${value?.shipping?.id}`
                );
                // setSelectedAddress(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address *"
                  variant="outlined"
                />
              )}
            />
          )}
        </Grid>


        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            fullWidth
            id="modules"
            size="small"
            options={modules || []}
            defaultValue={undefined}
            value={selectedModules || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select modules (optional)"
                placeholder="select multiple modules"
              />
            )}
            onChange={(event, value) => setSelectedModules(value || [])}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          style={{ backgroundColor: "grey" }}
          variant="contained"
          size="small"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 2 }}
          disabled={
            executing ||
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5 ||
            (!id && !type?.label)
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {`${id ? "Update" : "Create"} ${
            type && type?.label ? `${type?.label} : ` : ""
          } ${userName}`}
        </Button>
      </Toolbar>
    </Paper>
  );
}
