import React, { forwardRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { BASE_URL, DISABLED_MODULE } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

 const fetchAddresses = async (addressIds, shippingAddressIds) => {
  const sqlQuery = {
    query: `SELECT * FROM addresses WHERE id IN (${addressIds || 0},${
      shippingAddressIds || 0
    })`,
  };

  const res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);
  return res.data;
};

export const onSubmitAddress = async (
  allAddresses,
  setShippingAddressIds,
  setAddressIds
) => {
  try {
    const billingAddresses = allAddresses.map(({ billing }) => ({
      id: billing.id,
      address: billing.address,
    }));

    const simplifiedAddresses = allAddresses.map(
      ({ id, address, coordinates }) => ({
        id,
        address,
        coordinates,
      })
    );

    const combinedAddresses = [...billingAddresses, ...simplifiedAddresses];

    const oldAddress = combinedAddresses.filter(
      (address) => address.id !== null
    );

    let addressIdState = "";
    let shippingAddressIdState = "";

    const newBillingAddresses = billingAddresses
      .filter((address) => address.id === null)
      .map(({ address }) => ({ address }));

    const newSimplifiedAddresses = simplifiedAddresses
      .filter((address) => address.id === null)
      .map(({ address, coordinates }) => ({ address, coordinates }));

    const shippingPromise =
      newBillingAddresses.length > 0
        ? axiosWithToken.post(BASE_URL + "addresses/bulk", newBillingAddresses)
        : Promise.resolve(null);

    const addressPromise =
      newSimplifiedAddresses.length > 0
        ? axiosWithToken.post(
            BASE_URL + "addresses/bulk",
            newSimplifiedAddresses
          )
        : Promise.resolve(null);

    const [shippingResponse, addressResponse] = await Promise.all([
      shippingPromise,
      addressPromise,
    ]);

    if (shippingResponse) {
      const insertId = shippingResponse.data.insertId;
      const lengthOfArray = newBillingAddresses.length;

      const result = Array.from(
        { length: lengthOfArray },
        (_, index) => insertId + index
      ).join(",");

      shippingAddressIdState = result;
      setShippingAddressIds((prevIds) =>
        prevIds ? `${prevIds},${result}` : result
      );
    }

    if (addressResponse) {
      const insertId = addressResponse.data.insertId;
      const lengthOfArray = newSimplifiedAddresses.length;

      const result = Array.from(
        { length: lengthOfArray },
        (_, index) => insertId + index
      ).join(",");

      addressIdState = result;
      setAddressIds((prevIds) => (prevIds ? `${prevIds},${result}` : result));
    }

    if (oldAddress.length > 0) {
      await Promise.all(
        oldAddress.map(async (address) => {
          let response = await axiosWithToken.patch(
            BASE_URL + `addresses/${address.id}`,
            address
          );
        })
      );
    }

    // toast.success("Addresses saved successfully");

    return [addressIdState, shippingAddressIdState];
  } catch (error) {
    // Handle errors
    console.error("Error in onSubmit:", error);
  }
};

const MultiAddressInput = (
  {
    addressIds,
    shippingAddressIds,
    setAddressIds,
    setShippingAddressIds,
    allAddresses,
    setAllAddresses,
  },
  ref
) => {
  useEffect(() => {
    const fetchData = async () => {
      if (addressIds && shippingAddressIds) {
        const addresses1 = await fetchAddresses(addressIds, shippingAddressIds);
        const shippingAddressArray = addressIds.split(",").map(Number);
        const billingAddressArray = shippingAddressIds.split(",").map(Number);

        const result = shippingAddressArray.map((id, index) => {
          const address = addresses1.find(
            (a) => a.id !== undefined && a.id === id
          );
          const billingId = billingAddressArray[index];
          const billingInfo = addresses1.find((a) => a.id === billingId);

          const addressId = address ? address.id : null;
          const addressAddress = address ? address?.address : "";
          const coordinates = address ? address?.coordinates : null;

          return {
            id: addressId,
            address: addressAddress,
            billing: billingInfo
              ? { id: billingInfo.id, address: billingInfo.address }
              : { id: null, address: "" },
            coordinates: coordinates,
          };
        });

        setAllAddresses(result);
      }
    };

    fetchData();
  }, [addressIds, shippingAddressIds]);
  const handleAddAddress = () => {
    setAllAddresses((prevAddresses) => [
      ...prevAddresses,
      {
        id: null,
        address: "",
        billing: { id: null, address: "" },
        coordinates: null,
      },
    ]);
  };

  const handleDeleteAddress = async (index, addressID, billingID) => {
    try {
      await axiosWithToken
        .delete(BASE_URL + `addresses/${addressID}`)
        .then((res) =>
          setAddressIds(
            addressIds
              .split(",")
              .filter((id) => id !== String(addressID))
              .join(",")
          )
        );
      await axiosWithToken
        .delete(BASE_URL + `addresses/${billingID}`)
        .then((res1) =>
          setShippingAddressIds(
            shippingAddressIds
              .split(",")
              .filter((id) => id !== String(billingID))
              .join(",")
          )
        );

      setAllAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses.splice(index, 1);
        return updatedAddresses;
      });
    } catch (error) {
      // Handle errors here
      console.error("Error deleting addresses:", error);
    }
  };

  const handleAddressChange = (index, value) => {
    setAllAddresses((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index].address = value;
      return updatedAddresses;
    });
  };

  const handleBillingChange = (index, value) => {
    setAllAddresses((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index].billing.address = value;
      return updatedAddresses;
    });
  };

  const handleCoordinatesChange = (index, value) => {
    setAllAddresses((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index].coordinates = value;
      return updatedAddresses;
    });
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "rgb(245 245 245)" }}>
              <TableCell style={{ borderRight: "none" }}>
                <b style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Billing Address
                </b>
              </TableCell>
              {!DISABLED_MODULE?.includes("CoordinatesAddress") && (
                <TableCell style={{ borderRight: "none" }}>
                  <b style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Billing Address Coordinates
                  </b>
                </TableCell>
              )}
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    borderLeft: "none",
                  }}
                >
                  <b style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Shipping Address
                  </b>
                  <Button
                    id="client_edit_addaddrbtn"
                    variant="contained"
                    size="small"
                    onClick={handleAddAddress}
                  >
                    Add Address
                  </Button>
                </div>
              </TableCell>

              <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allAddresses?.map((address, index) => (
              <TableRow key={address.id}>
                <TableCell>
                  <TextField
                    id={`address_${address.id}`}
                    size="small"
                    fullWidth
                    label={`Address ${index + 1}`}
                    value={address.address}
                    onChange={(e) => handleAddressChange(index, e.target.value)}
                  />
                </TableCell>
                {!DISABLED_MODULE?.includes("CoordinatesAddress") && (
                  <TableCell>
                    <TextField
                      // id={`address_${address.id}`}
                      size="small"
                      fullWidth
                      label={`Latitude , Longitude`}
                      value={address.coordinates}
                      onChange={(e) =>
                        handleCoordinatesChange(index, e.target.value)
                      }
                    />
                  </TableCell>
                )}
                <TableCell>
                  <TextField
                    id={`billing_${address.id}`}
                    size="small"
                    fullWidth
                    label={`Billing ${index + 1}`}
                    value={address.billing.address}
                    onChange={(e) => handleBillingChange(index, e.target.value)}
                  />
                </TableCell>

                <TableCell>
                  <IconButton
                    onClick={() => {
                      const confirmation = window.confirm(
                        "Are you sure you want to delete this address?"
                      );
                      if (confirmation) {
                        handleDeleteAddress(
                          index,
                          address.id,
                          address.billing.id
                        );
                      }
                    }}
                  >
                    <DeleteIcon
                      id={`delete_${address.id}`}
                      style={{ color: "red", cursor: "pointer" }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Button
          id="client_edit_addaddrbtn"
          style={{ float: "right" }}
          variant="contained"
          size="small"
          onClick={() => onSubmitAddress(allAddresses, setShippingAddressIds, setAddressIds)}
>
          Save Address
        </Button> */}
      </TableContainer>
    </div>
  );
};

export default MultiAddressInput;
