import React, { useEffect } from "react";
import { BASE_URL, DOMAIN } from "./../../global";
import Tables from "../../utils/components/tables";
import moment from "moment";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  TableContainer,
  Toolbar,
  TextField,
  Autocomplete,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { showComponent } from "../helper/helpers";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "personName",
    label: "Competent Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "email",
    label: "Email",
    align: "left",
    minWidth: 40,
  },
  {
    id: "qualification",
    label: "Qualification",
    align: "left",
    minWidth: 40,
  },
  {
    id: "address",
    label: "Address",
    align: "left",
    minWidth: 40,
  },
  {
    id: "taluka",
    label: "Taluka",
    align: "left",
    minWidth: 40,
  },
  {
    id: "district",
    label: "District",
    align: "left",
    minWidth: 40,
  },
  {
    id: "contactNumber",
    label: "Contact Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "cpcNumber",
    label: "CPC Number",
    align: "left",
    minWidth: 40,
  },
];

const CompetentMasterList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [data, setFinalData] = React.useState([]);

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Competent Name": "personName",
        "CPC Number": "cpcNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    // if (_userType == 3)
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? `and cert.clientId = ${_clientId}`
    //       : `and cert.clientId = ${_clientId}`;
    // whereConditions +=
    //   whereConditions.length > 0
    //     ? " AND cert.status = 1"
    //     : "AND cert.status = 1";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT * from competents  ${whereConditions}  order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM competents  ${whereConditions}`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchFinalData() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setFinalData(res.data);
      })
      .catch((err) => {
        console.log("  error: ", err);
      });
  }

  const search = () => {
    fetchFinalData();
  };

  const handleDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `competents/${id}`)
      .then((res) => {
        toast("Competent is deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    getTotalRows();
  }, []);

  useEffect(() => {
    fetchFinalData();
  }, [rowsPerPage, page]);

  const actions = [
    {
      id: "edit",
      tooltip: "Edit Competent",
      link: true,
      linkTo: (row) => `/editCompetentMaster/${row.id}`,
      icon: <PreviewIcon />,
    },
    {
      id: "delete",
      tooltip: "Delete Competent",
      icon: <DeleteIcon style={{ color: "#dc3545" }} />,
      handler: (row) =>
        window.confirm("Are you sure you want to delete this?") &&
        handleDelete(row?.id),
    },
  ];

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2, p: 2 }}>
        <Toolbar>
          {showComponent("add") && (
            <Button
              variant="contained"
              size="small"
              component={Link}
              to="/editCompetentMaster"
            >
              <b>ADD NEW</b>
            </Button>
          )}
        </Toolbar>
        <br />
        <div
          className="mb-2"
          style={{
            display: "flex",
            float: "left",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            className="mb-2"
            style={{
              display: "flex",
              float: "left",
              justifyContent: "flex-end",
            }}
          >
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
                marginLeft: "10px",
              }}
              size="small"
              id="combo-box-demo"
              options={
                _userType != 3
                  ? [
                      { key: "personName", label: "Competent Name" },
                      { key: "cpcNumber", label: "CPC Number" },
                    ]
                  : [{ key: "personName", label: "Competent Name" }]
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"enter " + searchBy}
              size="small"
              variant="outlined"
              onChange={(e) => setSearchKey(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                search();
              }}
            >
              <SearchIcon />
            </Button>
          </div>
        </div>
        <br />
        <Tables
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleDelete={handleDelete}
          columns={columns}
          finalData={data}
          actions={actions}
          totalRows={totalRows}
        />
      </TableContainer>
    </div>
  );
};

export default CompetentMasterList;
