import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, useTheme, Divider } from "@mui/material";
import Chart from "./Chart";

import { BiUserPlus } from "react-icons/bi";
import { FaCalculator } from "react-icons/fa";
import { SlBasket } from "react-icons/sl";
import { ImCross } from "react-icons/im";
import { BASE_URL } from "./../../global";
import { TbBuildingWarehouse } from "react-icons/tb";
import { BsArrowRightCircleFill } from "react-icons/bs";
import "./style.css";
import { Link } from "react-router-dom";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import calImage from "../image/dashboard.jpg";

const ColorArray = [
  {
    bgColor: "linear-gradient(45deg, #4099ff, #3d6ba0)",
    darkBg: "#3d6ba0",
  },
  {
    bgColor: "linear-gradient(45deg, #37ceb0, #338f7c)",
    darkBg: "#338f7c",
  },
  {
    bgColor: "linear-gradient(45deg, #ffb64d, #8f6c3b)",
    darkBg: "#8f6c3b",
  },
  {
    bgColor: "linear-gradient(45deg, #ff5370, #97404f)",
    darkBg: "#97404f",
  },
];

export default function Dashboard() {
  const userId = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  const theme = useTheme();
  const [overAll, setOverAll] = useState({
    Templates: 0,
    Products: 0,
    Competents: 0,
    Users: 0,
    Clients: 0,
    Templates: 0,
  });

  const resultArray = Object.entries(overAll).map(([label, count]) => ({
    label,
    count: count < 10 ? `0${count}` : count,
  }));

  /////////////////////////////DashboardData////////////////////////

  const fetchCount = async (table, dateColumn, condition) => {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT * FROM ${table} WHERE DATE(${dateColumn}) ${condition}`;
      const response = await axiosWithToken.post(url, { query });
      return response.data.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const fetchOverallCount = async (table) => {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT * FROM ${table} `;
      const response = await axiosWithToken.post(url, { query });
      return response.data.length;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const fetchDataCounts = async () => {
    // const todaysClientsCount = await fetchCount('clients', 'lastModified', '= CURDATE()');
    // const todaysSrfsCount = await fetchCount('srfs', 'lastModified', '= CURDATE()');
    const overAllClients = await fetchOverallCount("clients");
    const overAllUsers = await fetchOverallCount("users");
    // const overAllCompetents = await fetchOverallCount("competents");

    setOverAll({
      Clients: overAllClients,
      Users: overAllUsers,
      // competents: overAllCompetents,
    });
  };

  useEffect(() => {
    fetchDataCounts();
  }, []);

  console.log("userType", userType);

  return (
    <>
      {userType == 3 ? (
        <div>
          {" "}
          <img style={{ width: "100%", height: "100%" }} src={calImage} />
        </div>
      ) : (
        <div>
          <Box p={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "16px",
                width: "content-width",
              }}
            >
              <Typography
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
                variant="h6"
                data-text="Overall Data:-"
              >
                Overall Activity:-
              </Typography>
            </div>
            <Box pb={2}>
              {/* Top Card */}
              <Grid container spacing={3}>
                {resultArray.map((card, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      sx={{
                        p: 4,
                        position: "relative",
                        borderRadius: "5px 5px 0px 0px",
                        background: `${ColorArray[index % 4].bgColor}`,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 3,
                          marginLeft: "-120px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: card.label === "User" ? "black" : "white",
                            fontWeight:
                              card.label === "User" ? "bold" : "extrabold",
                          }}
                        >
                          {card.count}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{
                            color: card.label === "User" ? "black" : "white",
                            fontWeight: 600,
                            fontFamily: "press",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                            paddingLeft: "120px",
                          }}
                        >
                          {card.label}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          py: 3,
                          color:
                            card.label === "User" ? "gray.400" : "gray.300",
                          opacity: 0.5,
                          px: 2,
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.1)",
                            duration: 500,
                          },
                        }}
                      >
                        {card.label === "Clients" ? (
                          <BiUserPlus size={80} />
                        ) : (
                          <TbBuildingWarehouse size={80} color="#fff" />
                        )}
                      </Box>
                    </Box>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 1,
                        gap: 2,
                        color: "white",
                        backgroundColor: `${ColorArray[index % 4].darkBg}`,
                        borderRadius: "0 0 4px 4px",
                        cursor: "pointer",
                      }}
                    ></Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Divider sx={{ borderTop: "2px solid" }} />
          <Box p={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "16px",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
                data-text="Engineer Report:-"
              >
                Daily Activity:-
              </Typography>
            </div>{" "}
            <Box pb={2}>
              {/* Top Card */}
              {/* <Grid container spacing={3}>
            {resultArray.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    p: 4,
                    position: "relative",
                    borderRadius: "5px 5px 0px 0px",
                    background: `${ColorArray[(index+2) % 4].bgColor}`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                      marginLeft: "-120px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: card.label === "User" ? "black" : "white",
                        fontWeight:
                          card.label === "User" ? "bold" : "extrabold",
                      }}
                    >
                      {card.count}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: card.label === "User" ? "black" : "white",
                        fontWeight: 600,
                        fontFamily: "press",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                        paddingLeft: "120px",
                      }}
                    >
                      {card.label}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      py: 3,
                      color: card.label === "User" ? "gray.400" : "gray.300",
                      opacity: 0.5,
                      px: 2,
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(1.1)",
                        duration: 500,
                      },
                    }}
                  >
                    {card.label === "Clients" ? (
                      <BiUserPlus size={80} />
                    ) : (
                      <TbBuildingWarehouse size={80} color="#fff" />
                    )}
                  </Box>
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    gap: 2,
                    color:"white",
                    backgroundColor: `${ColorArray[(index+2) % 4].darkBg}`,
                    borderRadius: "0 0 4px 4px",
                    cursor: "pointer",
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid> */}
            </Box>
          </Box>
          <Divider sx={{ borderTop: "2px solid" }} />
        </div>
      )}
    </>
  );
}
